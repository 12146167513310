import React from "react";
import { RichText } from "prismic-reactjs";
//import { withPreview } from "gatsby-source-prismic-graphql";
import moment from "moment";
import { linkResolver } from "@utilities/link-resolver.js";
import get from "lodash/get";
import { graphql, Link } from "gatsby";
import Layout from "@components/layout";
import slugify from "slugify";

import "@scss/blog-single.scss";

export const pageQuery = graphql`
  query blogSingleQuery($uid: String) {
    allPrismicBlog(filter: { uid: { eq: $uid }, lang: { eq: "en-us" } }) {
      edges {
        node {
          uid
          tags
          data {
            date
            headline
            content {
              text
              html
              raw
            }
            byline
          }
        }
      }
    }
  }
`;

const BlogSingle = (props) => {
  const data = props.data;
  const entry = get(data, "allPrismicBlog.edges[0].node");
  const date = entry.data.date && moment(entry.data.date);
  const tags = get(entry, "tags", []);
  return (
    <Layout>
      <div className="blog-single">
        <h2>{entry.data.headline}</h2>
        {entry.data.byline && (
          <div className="byline">By: {entry.data.byline}</div>
        )}
        <time>{date.format("dddd, MMMM Do YYYY")}</time>
        {RichText.render(entry.data.content.raw, linkResolver)}
        {tags.length > 0 && (
          <div className="tags">
            <span className="tags--label">Tags:</span>
            {tags.map((tag, index) => {
              const slug = slugify(tag.toLowerCase());
              return (
                <Link to={`/blog/tag/${slug}/`}>
                  <span className="tags--item" key={index}>
                    {tag}
                  </span>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default BlogSingle;
